import React, { useState } from "react";
import axios from "axios";
import { PopupModal } from "react-calendly";

import { useForm, useField } from "react-form";
import { toast } from "react-toastify";
import { baseURL } from "../assets/js/constant";

// import GoogleMapReact from "google-map-react";

// const AnyReactComponent = ({ text }) => <div>{text}</div>;

async function sendToFakeServer(values) {
  // const headers = {
  //   // Host: "api2gateway.kyron.in",
  //   // accept: "*/*",
  //   // "Content-Type": "application/json; charset=UTF-8",
  // };
  // const res = await axios
  //   .post(`https://api2gateway.kyron.in/contact-us`, values, {
  //     headers,
  //   })
  //   .then((response) => {
  //     // console.log(response.data);
  //     toast.success("Thank you! Our team will get back to you shortly.", {
  //       position: toast.POSITION.TOP_CENTER,
  //     });
  //   })
  //   .catch((err) => {
  //     // console.log(err.data);
  //     toast.error("Please try again! Something went wrong.", {
  //       position: toast.POSITION.TOP_CENTER,
  //     });
  //   })

    const formData = new FormData();
    formData.append("name", values.name)
    formData.append("email", values.email)
    formData.append("message", values.message)

    formData.append("access_key", "3bb739c9-12bb-47a2-9afb-48a04dd04a13");
    
    const response = await fetch("https://api.web3forms.com/submit", {
      method: "POST",
      body: formData
    });

    const data = await response.json();

    if (data.success) {
      toast.success("Thank you! Our team will get back to you shortly.", {
        position: toast.POSITION.TOP_CENTER,
      });
    } else {
      console.log("Error", data);
      toast.error("Please try again! Something went wrong.", {
        position: toast.POSITION.TOP_CENTER,
      });
    }

  // fetch("https://api2gateway.kyron.in/contact-us", {
  //   method: "POST",
  //   body: JSON.stringify(values),
  //   headers: headers,
  // })
  //   .then((response) => response.json())
  //   .then((json) => {
  //     console.log(json);
  //     toast.success("Thank you! Our team will get back to you shortly.", {
  //       position: toast.POSITION.TOP_CENTER,
  //     });
  //   })
  //   .catch((err) => {
  //     // console.log(err.data);
  //     toast.error("Please try again! Something went wrong.", {
  //       position: toast.POSITION.TOP_CENTER,
  //     });
  //   });
}

async function validateName(value, instance) {
  if (!value) {
    return "A name is required";
  }
  return instance.debounce(async () => {
    // console.log("checking name");
    await new Promise((resolve) => setTimeout(resolve, 100));
    // All names are valid, so return a false error
    return false;
  }, 100);
}
async function validSubject(value, instance) {
  if (!value) {
    return "A subject is required";
  }
  return instance.debounce(async () => {
    // console.log("checking subject");
    await new Promise((resolve) => setTimeout(resolve, 100));
    // All names are valid, so return a false error
    return false;
  }, 100);
}
async function validMessage(value, instance) {
  if (!value) {
    return "A message is required";
  }
  return instance.debounce(async () => {
    // console.log("checking message");
    await new Promise((resolve) => setTimeout(resolve, 100));
    // All names are valid, so return a false error
    return false;
  }, 100);
}

async function validEmail(value, instance) {
  if (!value) {
    return "Email is required";
  }
  if (!validateEmail(value)) {
    return "Please enter a valid email addresss";
  }

  return instance.debounce(async () => {
    // console.log("checking email");
    await new Promise((resolve) => setTimeout(resolve, 100));
    // All names are valid, so return a false error
    return false;
  }, 100);
}

function validateEmail(email) {
  var re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

function NameField() {
  const {
    meta: { error, isTouched, isValidating },
    getInputProps,
  } = useField("name", {
    validate: validateName,
  });

  return (
    <>
      <div className="form__group">
        <input
          {...getInputProps()}
          type="text"
          className="form__input"
          placeholder="Full name"
          id="name"
          required
        />

        <label htmlFor="name" className="form__label">
          {isTouched && error ? <em>{error}</em> : null}
        </label>
      </div>
    </>
  );
}

function SubjectField() {
  const {
    meta: { error, isTouched, isValidating },
    getInputProps,
  } = useField("subject", {
    validate: validSubject,
  });

  return (
    <>
      <div className="form__group">
        <input
          {...getInputProps()}
          type="text"
          className="form__input"
          placeholder="Subject"
          id="subject"
          required
        />

        <label htmlFor="subject" className="form__label">
          {isTouched && error ? <em>{error}</em> : null}
        </label>
      </div>
    </>
  );
}

function MessageField() {
  const {
    meta: { error, isTouched, isValidating },
    getInputProps,
  } = useField("message", {
    validate: validMessage,
  });

  return (
    <>
      <div className="form__group">
        <textarea
          {...getInputProps()}
          type="text"
          className="form__input"
          placeholder="Message"
          id="message"
          required
        />

        <label htmlFor="message" className="form__label">
          {isTouched && error ? <em>{error}</em> : null}
        </label>
      </div>
    </>
  );
}

function EmailField() {
  const {
    meta: { error, isTouched, isValidating },
    getInputProps,
  } = useField("email", {
    validate: validEmail,
  });

  return (
    <>
      <div className="form__group">
        <input
          {...getInputProps()}
          type="email"
          className="form__input"
          placeholder="Email"
          id="email"
          required
        />

        <label htmlFor="email" className="form__label">
          {isTouched && error ? <em>{error}</em> : null}
        </label>
      </div>
    </>
  );
}

function MyForm() {
  // Use the useForm hook to create a form instance

  const {
    Form,
    meta: { isSubmitting, canSubmit },
  } = useForm({
    onSubmit: async (values, instance) => {
      // onSubmit (and everything else in React Form)
      // has async support out-of-the-box
      await sendToFakeServer(values);
      console.log("Huzzah!");
    },
    debugForm: false,
  });

  return (
    <Form className="form">
      <div className="u-margin-bottom-medium">
        <h2 className="heading-secondary">Have some questions ?</h2>
      </div>
      <NameField />
      <EmailField />
      <SubjectField />
      <MessageField />

      <div className="form__group u-margin-top-big ">
        {!isSubmitting ? (
          <button
            className="btn btn--green"
            type="submit"
            disabled={!canSubmit}
          >
            Send &rarr;
          </button>
        ) : (
          <div className="loader"></div>
        )}
      </div>
    </Form>
  );
}

function ContactPage() {
  const [isCalenderPopup, setCalendarPopup] = useState(false);
  // const defaultProps = {
  //   center: {
  //     lat: 59.95,
  //     lng: 30.33,
  //   },
  //   zoom: 11,
  // };
  return (
    <div>
      <section className="section-company">
        <h3 className="section-company__heading">Contact Us</h3>
        <p className="section-company__subheading">
          {" "}
          You can reach out to us or schedule a{" "}
          <a
            href="#"
            className="section-company-form-link"
            onClick={(e) => {
              e.preventDefault();
              setCalendarPopup(true);
            }}
          >
            demo
          </a>
        </p>

        <div className="section-company-detail">
          <h3 className="section-company-detail__heading">Sales Inquiries:</h3>
          <p className="section-company-detail__subheading">
            Email:{" "}
            <a
              href="mailto:sales@kyron.in"
              className="section-company-form-link"
            >
              {" "}
              sales@kyron.in
            </a>
          </p>
          <p className="section-company-detail__subheading">
            Phone:{" "}
            <a href="tel:+91-8800966082" className="section-company-form-link">
              +91-8800966082
            </a>
          </p>
          <h3 className="section-company-detail__heading u-margin-top-xssmall">
            Support:
          </h3>
          <p className="section-company-detail__subheading">
            Email:{" "}
            <a
              href="mailto:helpdesk@kyron.in"
              className="section-company-form-link"
            >
              {" "}
              helpdesk@kyron.in
            </a>
          </p>
          <p className="section-company-detail__subheading">
            Phone:{" "}
            <a href="tel:+91-8800966082" className="section-company-form-link">
              +91-8800966082
            </a>
          </p>
        </div>

        <div className="contact-form u-margin-top-small">
          <div className="contact-form-content ">
            {/* <h3 className="contact-form-content-heading">
          About Us
        </h3> */}

            <MyForm />
          </div>
          <div className="contact-form-mapbox">
            <div className="contact-form-mapbox-map">
              <iframe
                title="Google map"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3504.7958920963156!2d77.2709875150807!3d28.545854082452102!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390ce3e564daac1d%3A0x2c582e340e7bc556!2sIndraprastha%20Institute%20of%20Information%20Technology%20Delhi!5e0!3m2!1sen!2sin!4v1649841593931!5m2!1sen!2sin"
                width="100%"
                height="100%"
                style={{ border: 0 }}
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
              ></iframe>
              {/* <GoogleMapReact
                bootstrapURLKeys={{
                  key: "AIzaSyDkxD3LFJS4KrF5pbfcsT6x9oCnMq5lTpo",
                }}
                defaultCenter={defaultProps.center}
                defaultZoom={defaultProps.zoom}
              >
                <AnyReactComponent
                  lat={59.955413}
                  lng={30.337844}
                  text="My Marker"
                />
              </GoogleMapReact> */}
            </div>
          </div>
        </div>
      </section>
      <PopupModal
        url="https://calendly.com/kyron-sales/30min"
        // pageSettings={this.props.pageSettings}
        // utm={this.props.utm}
        // prefill={this.props.prefill}
        onModalClose={() => setCalendarPopup(false)}
        open={isCalenderPopup}
        /*
         * react-calendly uses React's Portal feature (https://reactjs.org/docs/portals.html) to render the popup modal. As a result, you'll need to
         * specify the rootElement property to ensure that the modal is inserted into the correct domNode.
         */
        rootElement={document.getElementById("root")}
      />
    </div>
  );
}

export default ContactPage;
